// components/Description.js
import React from 'react';

const Description = () => (
   <div className="description">
      <p className="title">FREE CASE OPENING PROVIDED BY <span className="name">SKINVALUATION</span></p>
      <p className="text">
         <span className="b">SKINVALUATION</span> are holding a promotion. Completely free case opening. Open and remove skin.
      </p>
   </div>
);

export default Description;
