// Terms.js
import React from 'react';

const Terms = () => (
  <div>
    <div className="description">
      <p className="title">User Agreement of <span className="name">SKINVALUATION</span></p>
    </div>
    <p>By using our site, you automatically accept the following terms and conditions.</p>
    
    <h2 className='terms-heading'>Basic Terms and Definitions</h2>
    <ul className='terms-list'>
      <li>The website is skinValuation</li>
      <li>You are a person using the site</li>
      <li>Services are the services provided through this website.</li>
    </ul>
    
    <h2 className='terms-heading'>1. General Information</h2>
    <p>
      We make every possible effort to protect this website from malware, viruses, etc. However, we cannot fully guarantee their absence. If you download anything from the site, you do so at your own risk. All content on the site is the property of the game developer or licensors and is protected by international copyright laws.
    </p>
    
    <h2 className='terms-heading'>2. Possible Risk</h2>
    <p>
      According to the Terms and Conditions of most online games, virtual game items cannot be bought or sold. In this case, the provider has the right to block your account due to violations of the terms of service agreements of the companies providing game services and item exchanges. The website is not responsible for any actions taken regarding your game account after a transaction. The website will not reimburse you if your game account is suspended.
    </p>
    
    <h2 className='terms-heading'>3. Lost and Stolen Items</h2>
    <p>
      You are solely responsible for unauthorized access to your account, including via the Steam API Key. The website will not replace services or products if they are stolen or lost after the transaction. This also applies to partially fulfilled agreements.
    </p>
    
    <h2 className='terms-heading'>4. Controversial Issues</h2>
    <p>
      You cannot cancel the agreement yourself after the first confirmation on Steam. The agreement can only be terminated unilaterally if approved by support staff, without explanation. The website commits to paying the amount specified in the invoice letter. If unable to do so, alternative payment details may be requested. If alternative methods are not provided, the website may choose to return items at its discretion. If payment is successfully sent, items will not be returned. Complaints can be sent to support@volt.skin. Our team will review and respond as soon as possible. No third-party protection is available for virtual services and goods. Internal trade history is used to confirm transactions. If the transaction is marked as complete, it is considered finalized.
    </p>
    
    <h2 className='terms-heading'>5. Escrow Service</h2>
    <p>
      You cannot use the website's escrow service. If suspected of using the website as an escrow service, funds may be withheld for 72 hours for investigation. Post-investigation, further cooperation will be decided. The website disclaims responsibility when sellers provide information to third parties, and sellers waive rights to demand agreement performance.
    </p>
    
    <h2 className='terms-heading'>6. Copyright</h2>
    <p>
      Trademarks and copyrights belong to their respective owners. Content on the site is the property of the website. Software is owned by the site or its suppliers.
    </p>
    
    <h2 className='terms-heading'>7. Indemnification</h2>
    <p>
      You agree to indemnify and hold harmless the Website, its contractors, and content providers from any claims and expenses, including attorneys' fees, arising from termination. You are responsible for content distribution on the site.
    </p>
    
    <h2 className='terms-heading'>8. Remedy</h2>
    <p>
      This agreement does not confer rights or remedies at law to any person other than the parties to this agreement.
    </p>
    
    <h2 className='terms-heading'>9. Autonomy</h2>
    <p>
      If any part of this Agreement is held invalid or unenforceable, it shall be construed according to applicable law to best reflect the intent of the Website. Other provisions remain in effect.
    </p>
  </div>
);

export default Terms;

