import React from 'react';
import Description from './Description';
import LatestSkins from './LatestSkins';

const Main = () => {
    const images = [
        { src: 'assets/img/img1.png', alt: 'image' },
        { src: 'assets/img/img2.png', alt: 'image' },
        { src: 'assets/img/img3.png', alt: 'image' },
        { src: 'assets/img/img4.png', alt: 'image' },
        { src: 'assets/img/img5.png', alt: 'image' }
    ];

    return (
        <div className="main-content">
            <div className="roll-zone" style={styles.rollZone}>
                {images.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} style={styles.image} />
                ))}
            </div>
            <Description />
            <LatestSkins />
        </div>
    );
};

const styles = {
    rollZone: {
        marginTop: '100px', 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '5px',
    },
    image: {
        width: '200px',
    },
};

export default Main;
