// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
   <footer>
      <div className="logo">
         <img src="assets/img/logo.png" alt="Logo" />
         <p>skinValuation</p>
      </div>
      <nav aria-label="footer">
         <Link to="/">Home</Link>
         <Link to="/about">About Us</Link>
         <Link to="/privacy">Pravicy Policy</Link>
         <Link to="/terms">User Agreement</Link>
      </nav>
      <p className="footer-text">© Copyright 2024 All Rights Reserved.</p>
   </footer>
);

export default Footer;
